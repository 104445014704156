import { Ability } from '@casl/ability'
import { getNavigation } from '../../navigation/vertical'
import { store } from '../../redux/store'
import { initialAbility } from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))
const existingAbility = userData ? userData.ability : null

const navigationCall=async ()=>{

    if(userData!=null){
        let accountType=localStorage.getItem('accountType');
        let role=userData.role;
        if(accountType){
            role=accountType;
        }
        console.log(accountType);
        const navigation =  await getNavigation(role,userData.brand_applied,userData.influencer)
        store.dispatch({type:"CHANGE_ACCESS",payload: navigation})
    }
}

navigationCall();


export default new Ability(existingAbility || initialAbility)
