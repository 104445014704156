// ** Icons Import
import { Mail, Map,  MessageSquare,Layers, CheckSquare, Calendar, FileText, Circle, ShoppingCart, User, Shield, Disc, Sliders, Activity, Home, Music, PlusCircle, Star } from 'react-feather'
import themeConfig from '../../configs/themeConfig'

export default [
  {
    id:'0',
    title:'Home',
    icon:<Home size={14}/>,
    navLink:themeConfig.app.headRoute+'/Home'
  },
  {
    id:'1',
    title:'My Songs',
    icon:<Activity size={14}/>,
    navLink:themeConfig.app.headRoute+'/manage/artistmusic'
  },

  {
    id:'2',
    title:'Verifications',
    icon:<Shield size={14}/>,
    navLink:themeConfig.app.headRoute+'/manage/verifications'
  },
  {
    id:'4',
    title:'Users',
    icon:<MessageSquare size={14}/>,
    navLink:themeConfig.app.headRoute+'/Manage/User/Admin'
  },
  {
    id:'5',
    title:'Add Song',
    icon:<PlusCircle size={14}/>,
    navLink:themeConfig.app.headRoute+'/Manage/addsong'
  },

  {
    id:'6',
    title:'Sounds List',
    icon:<Music size={14}/>,
    navLink:themeConfig.app.headRoute+'/Manage/soundslist'
  },
  {
    id:'7',
    title:'Profile',
    icon:<User size={14}/>,
    navLink:themeConfig.app.headRoute+'/account'
  },
  {
    id:'8',
    title:'Duet Ad Video',
    icon:<Disc size={14}/>,
    navLink:themeConfig.app.headRoute+'/brandform'
  },
  {
    id:'9',
    title:'Make Ad Video',
    icon:<CheckSquare size={14}/>,
    navLink:themeConfig.app.headRoute+'/brandformscript'
  },
  {
    id:'10',
    title:'Brand Requests',
    icon:<Disc size={14}/>,
    navLink:themeConfig.app.headRoute+'/brandrequests'
  },
  {
    id:'11',
    title:'My Adverts',
    icon:<Activity size={14}/>,
    navLink:themeConfig.app.headRoute+'/mybrandrequests'
  },
  {
    id:'12',
    title:'Transaction',
    icon:<Activity size={14}/>,
    navLink:themeConfig.app.headRoute+'/transactions'
  },
  {
    id:'13',
    title:'Verification (Tiktokers)',
    icon:<Activity size={14}/>,
    navLink:themeConfig.app.headRoute+'/Manage/adminverificationsusers'
  },
  {
    id:'14',
    title:'Add CapCut',
    icon:<PlusCircle size={14}/>,
    navLink:themeConfig.app.headRoute+'/Manage/addcatcupsong'
  },
  {
    id:'15',
    title:'Influencers Sounds',
    icon:<Music size={14}/>,
    navLink:themeConfig.app.headRoute+'/Manage/influencersoundslist'
  },
  {
    id:'16',
    title:'CapCut List',
    icon:<Music size={14}/>,
    navLink:themeConfig.app.headRoute+'/Manage/catcupsoundslist'
  },
  {
    id:'17',
    title:'Sound List',
    icon:<Music size={14}/>,
    navLink:themeConfig.app.headRoute+'/admin/soundlist'
  },
  {
    id:'18',
    title:'Statics',
    icon:<Star size={14}/>,
    navLink:themeConfig.app.headRoute+'/admin/stats'
  },
  {
    id:'19',
    title:'Sounds List Admin',
    icon:<Music size={14}/>,
    navLink:themeConfig.app.headRoute+'/Manage/soundslistadmin'
  },
  
]